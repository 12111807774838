<template>
  <section>
    <VRow v-if="loading">
      <VCol
        align="center"
        data-test="progress-circular"
      >
        <VProgressCircular
          indeterminate
          color="primary"
        />
      </VCol>
    </VRow>
    <template v-if="error">
      <div data-test="message-error">
        {{ error }}
      </div>
    </template>
    <template v-if="success">
      <div data-test="message-success">
        {{ success }}
      </div>
    </template>
  </section>
</template>

<script>
import * as snamiApi from '@/services/backend/snami';

export default {
  name: 'Oauth',
  data: () => ({
    loading: false,
    error: null,
    success: null,
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      const code = this.$route.query?.code;
      if (code) {
        this.loading = true;
        snamiApi.potokOauthCallback({
          code,
        })
          .then(() => {
            this.success = 'Связь установлена';
            if (this.$route.query?.redirect_uri) {
              window.location.replace(this.$route.query?.redirect_uri);
            }
          })
          .catch((e) => {
            console.error(e);
            this.error = 'Ошибка при отправке запроса';
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.error = 'Не передан код';
      }
    },
  },
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
